<template>
  <div :class="['group_form', 'select-quantity', { on: isDirectMode }]">
    <Input
      v-if="isDirectMode"
      class="select-quantity__input"
      :maxLength="6"
      :value.sync="unitValueSync"
      :isDisabled="isDisabledSelectBox"
    />
    <SelectBox
      class="select-quantity__select"
      :dataList="quantityList"
      :value.sync="unitTypeSync"
      :isDisabled="isDisabledSelectBox"
      @updateItem="onChangeSelect"
    />
  </div>
</template>

<script>
import SelectBox from "./SelectBox";
import Input from "@/components/common/input/Input";
import InputNumber from "@/components/common/input/InputNumber";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";

import { mapState } from "vuex";
import { COMMON_CODE_ACTION } from "@/store/modules/common/action";

export default {
  //직업입력 tf
  name: "SelectBoxQuantity",
  components: {
    SelectBox,
    Input,
    InputNumber,
    InputAutoMoney,
  },
  props: {
    unitValue: String,
    unitType: String,
    isDisabledSelectBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(COMMON_CODE_ACTION.GET_QUANTITY);
  },
  computed: {
    ...mapState({
      quantityList: (state) => state.commonCode.quantity,
    }),
    isDirectMode() {
      return this.unitType === "T";
    },
    unitValueSync: {
      get() {
        return this.unitValue;
      },
      set(value) {
        this.$emit("update:unitValue", value);
      },
    },
    unitTypeSync: {
      get() {
        return this.unitType;
      },
      set(value) {
        this.$emit("update:unitType", value);
      },
    },
  },
  methods: {
    onChangeSelect(value) {
      if (value.code !== "T") {
        this.$emit("update:unitValue", value.name);
      } else {
        this.$emit("update:unitValue", "");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-quantity {
  display: flex;
  &__select {
  }
  &__input {
  }
}
</style>
