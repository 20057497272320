import { getMoneyToNumber } from "@/utils/stringUtils.js";

export default class ItemWriteModel {
  constructor() {
    this.objRaw = {};
    this.isModifyMode = false;
    this.id = "";

    this.category0 = null;
    this.category1 = null;
    this.category2 = null;
    this.cateNum = null;
    this.itemName = ""; //품목명
    this.displayCode = ""; //품목코드
    this.itemCode = "자동생성"; //품목코드
    this.standard = ""; //규격
    this.unitType = "E"; //단위
    this.unitValue = "EA"; //단위
    this.price = ""; //단가
    this.currency = "KRW"; //원
    this.store = ""; //입고처
    this.warranty = ""; //보증기간
    this.status = "R"; //상태
    this.reason = ""; //상태
    this.manufacturer = ""; //제조사
    this.note = ""; //비고
    this.attachFileList = []; //첨부파일
  }

  setData(obj) {
    this.objRaw = _.cloneDeep(obj);
    const {
      category0,
      category1,
      category2,
      cateNum,
      itemName,
      itemCode,
      displayCode,
      standard,
      status,
      reason,
      price,
      currency,
      store,
      warranty,
      manufacturer,
      note,
      unitType,
      unitValue,
      attachFileList,
    } = obj;
    this.cateNum = Boolean(cateNum) ? Number(cateNum) : null;
    this.category0 = Boolean(category0) ? Number(category0) : null;
    this.category1 = Boolean(category1) ? Number(category1) : null;
    this.category2 = Boolean(category2) ? Number(category2) : null;
    this.itemName = itemName || "";
    this.itemCode = itemCode;
    this.displayCode = displayCode;
    this.price = price || "";
    this.currency = currency || "KRW";
    this.standard = standard || "";
    this.unitType = unitType || "E";
    this.unitValue = unitValue || "EA";
    this.reason = reason || "";
    this.status = status || "";
    this.store = store || "";
    this.warranty = warranty || "";
    this.manufacturer = manufacturer || "";
    this.note = note || "";

    this.attachFileList = attachFileList || [];
  }

  // Send Data
  getData() {
    const obj = {
      category0: this.category0 || "",
      category1: this.category1 || "",
      category2: this.category2 || "",
      cateNum: this.category2 || this.category1 || this.category0,
      itemName: this.itemName,
      standard: this.standard,
      price: this.price != null ? Number(getMoneyToNumber(this.price)) : 0,
      currency: this.currency,
      store: this.store,
      warranty: this.warranty != null ? Number(this.warranty) : 0,
      reason: this.reason,
      status: this.status,
      displayCode: this.displayCode,
      manufacturer: this.manufacturer,
      note: this.note,
      unitType: this.unitType,
      unitValue: this.unitValue,
      attachFileList: this.attachFileList,
    };

    return Object.assign({}, this.objRaw, obj);
  }

  // Validation Check
  getAlertMessage() {
    if (!this.category0) {
      return "대분류를 선택해 주세요.";
    }

    if (!this.itemName) {
      return "품목명을 입력해 주세요.";
    }

    if (this.warranty != null && this.warranty.length > 3) {
      return "입력 필드의 길이를 확인해 주세요. (보증기간: 최대 3자리)";
    }
    if (this.status === "J" && !this.reason) {
      return "사유를 입력해 주세요";
    }
    return null;
  }
}
