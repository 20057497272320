<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">품목 상세</h2>
      </div>
      <div class="body_section">
        <div class="area_view">
          <TableViewWithTitle title="품목 정보">
            <template v-slot:body>
              <tr>
                <th>분류</th>
                <td colspan="3">
                  {{ category }}
                </td>
              </tr>
              <tr>
                <th>품목코드</th>
                <td>{{ itemData.displayCode }}</td>
                <th>상태</th>
                <td>{{ itemStatusName }}</td>
              </tr>
              <tr>
                <th>품목명</th>
                <td colspan="3">
                  {{ itemData.itemName }}
                </td>
              </tr>
              <tr v-if="itemData.status === 'J'">
                <th>사유</th>
                <td colspan="3">
                  {{ itemData.reason }}
                </td>
              </tr>
              <tr>
                <th>단위</th>
                <td>{{ itemData.unitValue }}</td>
                <th>단가</th>
                <td>{{ itemData.price | currency }}</td>
              </tr>
              <tr>
                <th>제조사</th>
                <td>{{ itemData.manufacturer }}</td>
                <th>보증기간</th>
                <td>{{ itemData.warranty }}개월</td>
              </tr>
              <tr>
                <th>규격</th>
                <td>{{ itemData.standard }}</td>
                <th />
                <td />
              </tr>
              <tr>
                <th>품목상세</th>
                <td colspan="3" v-html="noteDecoded" />
              </tr>
              <tr>
                <th>파일 첨부</th>
                <td colspan="3">
                  <FileView :dataList="itemData.attachFileList || []" />
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </div>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <template v-if="$isAdmin || (!$isAdmin && itemData.status === 'R' && isMine)">
          <button type="button" class="btn_primary btn_large" @click="onClickModify">수정</button>
        </template>
        <template v-if="$isAdmin && itemData.status === 'R'">
          <button type="button" class="btn_fifthly btn_large" @click="onClickPopDelete">
            삭제
          </button>
        </template>
      </div>
    </Sticky>
    <template v-slot:popup>
      <AlertPopup
        v-if="isDeletePopupMode"
        alertText="해당 품목을 삭제하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickClose"
        @onClickPrimary="onClickDelete"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import ItemInfo from "@/components/admin/item/write/ItemInfo";
import FileView from "@/components/common/file/FileView";
import TableHead from "@/components/shared/TableHead";
import Board from "@/components/common/board/Board";
import Sticky from "@/components/layout/content/Sticky.vue";
import AlertPopup from "@/components/layout/popup/AlertPopup";

import PageMixin from "@/mixins/PageMixin";

import ItemWriteModel from "./ItemWriteModel";

import { getDateString, getDateStringWithTime } from "@/utils/dateUtils";
import { addCommaForMoney, decodeHTMLEntities, getIsValidHtml } from "@/utils/stringUtils";
import {
  getCategoryDepthText,
  getEvaluateData,
  getCountWithCurrency,
  getCountWithQuantity,
} from "@/utils/spUtils";
import { getPurchaseDraftDetailPath } from "@/utils/DetailPathUtils";
import { escapeRegExp } from "@/utils/spUtils";
import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";
import { makePathWithQuery } from "@/utils/urlUtils";
import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";

export default {
  name: "ItemView",
  components: {
    PageWithLayout,
    TableViewWithTitle,
    ItemInfo,
    TableHead,
    Board,
    FileView,
    Sticky,
    AlertPopup,
  },
  mixins: [PageMixin],
  data() {
    return {
      model: new ItemWriteModel(),
      itemData: {},
      itemId: "",
      isPopDetail: false,
      requestCidSelected: String,
      isReuploadPopupMode: false,
      isDeletePopupMode: false,
      isPopAnnounceClose: false,
    };
  },
  computed: {
    noteDecoded() {
      if (!this.itemData.note) {
        return;
      }
      const note = this.itemData.note;

      return escapeRegExp(note);
    },
    category() {
      if (!this.itemData || !this.itemData.category) return "";
      return getCategoryDepthText(this.itemData.category);
    },
    myLoginIdWithName() {
      return LocalStorageManager.shared.getUserLoginIdWithName();
    },
    itemStatusName() {
      const status = this.itemData.status;
      switch (status) {
        case "R":
          return "대기";
        case "C":
          return "사용중";
        case "S":
          return "사용중지";
        case "J":
          return "사용불가";
        default:
          return "";
      }
    },
    isMine() {
      const { empNo } = LocalStorageManager.shared.getUserData();
      const { regId } = this.itemData;
      return empNo === regId;
    },
  },
  created() {
    this.itemCode = this.$route.params.id;
    const path = `${this.$apiPath.ITEM}/${this.itemCode}`;
    this.$store.dispatch(PROGRESS_START_ACTION);
    this.getData(path);
  },
  methods: {
    async getData(path) {
      const result = await ApiService.shared.getData(path);
      this.$store.dispatch(PROGRESS_END_ACTION);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.itemData = data;
    },
    onClickClose() {
      this.isReuploadPopupMode = false;
      this.isDeletePopupMode = false;
    },
    onClickCloseDetail() {
      this.isPopDetail = false;
    },
    onAlert(text) {
      this.alert(text);
    },
    onClickGoToList() {
      this.$router.push({
        path: this.$routerPath.ITEM_LIST,
        query: this.$route.query,
      });
    },
    onClickModify() {
      this.$router.push(
        makePathWithQuery(this.$routerPath.ITEM_MODIFY, this.itemCode, this.$route.query),
      );
    },
    onClickDelete() {
      this.deleteItem();
    },
    onClickPopDelete() {
      if (this.itemData.status !== "R") {
        this.alert("상태가 대기일때만 삭제가 됩니다.");
        return;
      }
      this.isDeletePopupMode = true;
    },
    async deleteItem() {
      const path = `${this.$apiPath.ITEM}/${this.itemCode}`;
      const result = await ApiService.shared.deleteData(path);
      const msg = parseInt(result.code) !== 200 ? result.text : "품목이 삭제되었습니다.";

      this.$router.push({ path: this.$routerPath.ITEM_LIST });

      this.alert(msg);
      this.isDeletePopupMode = false;
    },
  },
};
</script>
