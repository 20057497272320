<template>
  <TableViewWithTitle title="품목 정보">
    <template v-slot:body>
      <tr>
        <th>분류<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <CategoryGroupIncludeData
            ref="category"
            :category0.sync="model.category0"
            :category1.sync="model.category1"
            :category2.sync="model.category2"
          />
        </td>
      </tr>
      <tr>
        <th>품목코드</th>
        <!-- <td v-if="!model.isModifyMode">{{ model.displayCode }}</td> -->
        <td>
          {{ model.displayCode | nullToDash }}
        </td>
        <th>상태</th>
        <td>
          <SelectBox
            :isDisabled="!model.isModifyMode || $isUser || $isInspect"
            class="w152"
            :dataList="itemStatusList"
            :value.sync="model.status"
          />
        </td>
      </tr>
      <tr v-if="model.status === 'J'">
        <th>사유<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <Input :value.sync="model.reason" placeholder="사유를 입력하세요." />
        </td>
      </tr>
      <tr>
        <th>품목명<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <Input
            :value.sync="model.itemName"
            :isDisabled="false"
            placeholder="품목명을 입력하세요."
          />
        </td>
      </tr>
      <tr>
        <th>단위</th>
        <td>
          <div class="row_pack">
            <SelectBoxQuantity
              :isDisabledSelectBox="false"
              :isDisabledInputCount="false"
              :unitValue.sync="model.unitValue"
              :unitType.sync="model.unitType"
            />
          </div>
        </td>
        <th>단가</th>
        <td>
          <InputAutoMoney :value.sync="model.price" />
        </td>
      </tr>
      <tr>
        <th>제조사</th>
        <td>
          <Input
            :value.sync="model.manufacturer"
            :isDisabled="false"
            placeholder="제조사를 입력하세요."
            :maxLength="100"
          />
        </td>

        <th>보증기간(개월)</th>
        <td>
          <InputNumber
            :maxLength="2"
            :maxValue="36"
            :isDisabled="false"
            :value.sync="model.warranty"
            placeholder="36개월을 초과할수 없습니다."
          />
        </td>
      </tr>
      <tr>
        <th>규격</th>
        <td>
          <Input
            :value.sync="model.standard"
            :isDisabled="false"
            placeholder="규격을 입력하세요."
          />
        </td>

        <th></th>
        <td></td>
      </tr>
      <tr>
        <th>비고</th>
        <td colspan="3" class="h96">
          <Textarea placeholder="비고" :value.sync="model.note" :maxLength="300" />
        </td>
      </tr>
      <tr>
        <th>첨부 파일</th>
        <td colspan="3">
          <FileWrite
            :dataList="model.attachFileList"
            @fileUploaded="fileUploaded"
            @fileRemoved="fileRemoved"
          />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";
import Input from "@/components/common/input/Input";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import InputNumber from "@/components/common/input/InputNumber";

import Textarea from "@/components/common/textarea/Textarea";
import SelectBox from "@/components/common/selectBox/SelectBox";
import SelectBoxQuantity from "@/components/common/selectBox/SelectBoxQuantity";
import SelectBoxCurrencyIncludeData from "@/components/common/selectBox/SelectBoxCurrencyIncludeData";

import LocalStorageManager from "@/LocalStorageManager";

import FileWrite from "@/components/common/file/FileWrite";

export default {
  name: "ItemInfo",
  components: {
    TableViewWithTitle,
    CategoryGroupIncludeData,
    Input,
    InputAutoMoney,
    InputNumber,
    Textarea,
    SelectBox,
    SelectBoxQuantity,
    SelectBoxCurrencyIncludeData,
    FileWrite,
  },
  props: {
    model: Object,
    itemStatusList: Array,
  },
  computed: {
    // hasAdmin(){
    //  return LocalStorageManager.shared.
    // }
  },
  mounted() {
    this.setCategoryForce(this.model.category0, this.model.category1, this.model.category2);
  },
  methods: {
    fileUploaded(files) {
      this.model.attachFileList = this.model.attachFileList.concat(files);
    },
    fileRemoved(file) {
      this.model.attachFileList = this.model.attachFileList.filter((item) => item !== file);
    },
    setCategoryForce(category0, category1, category2) {
      this.$refs.category.setForce(category0, category1, category2);
    },
  },
};
</script>
